import React from 'react';

const BandPage = ({ band }) => {
  return (
  <div>
    <h3>{band.name}</h3>
    <div>
      TODO: upcoming shows
    </div>
    <div>
      TODO: past shows
    </div>
    <div>
      TODO: 
      Youtube vids?
    </div>
  </div>

  );
};

export default BandPage;
